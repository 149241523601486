import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import ContentService from '../../services/content.service'


function* getContentList(action) {
  try {
    //console.log('getContentList action userSagas============ ',action);
    if (action.payload) {
      const page = action.payload?.pageNo;
      const perPage = action.payload?.perPage;
      const category = action.payload?.catName;
      const search = action.payload?.search;
      const status = action.payload?.statusType;
      let params = {page, perPage, category, search, status};

      const response = yield ContentService.list(params);
      // console.log('getContentList response userSagas============ ',response);
      if (response?.data.statusCode === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.getContentListSuccess(response.data));
      } else {
        yield put(actions.getContentListFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getContentListFailure(error));
  }
}

function* managePost(action){
  try {
    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    console.log('managePost contentSagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield ContentService.managePost(payload);
    //console.log('managePost response contentSagas============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data contentSagas ',response.data);
      yield put(actions.managePostSuccess(response.data));
    } else {
      yield put(actions.managePostFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* manageSeo(action){
  try {
    let response;
    let payload = action.payload;
    let create = payload.create;
    delete payload.create;
    // console.log('managePost contentSagas ',create,' payload ',payload);
    if (create) {
      // create user flow
      delete payload._id
    }
    response = yield ContentService.manageSeo(payload);
    //console.log('managePost response contentSagas============ ',response);
    if (response?.data.statusCode === 200) {
      //console.log('response.data contentSagas ',response.data);
      yield put(actions.manageSeoSuccess(response.data));
    } else {
      yield put(actions.manageSeoFailure("network error"));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* getSeoData(action) {
  try {
    if (action.payload) {
      const post_id = action.payload?.post_id;
      const category_id = action.payload?.category_id;
      const _id = action.payload?._id;

      let params = {post_id, category_id,_id};

      const response = yield ContentService.getSeo(params);
      if (response?.status === 200) {
        //console.log('response.data userSaga ',response.data);
        yield put(actions.getSeoSuccess(response.data.result[0]));
      } else {
        yield put(actions.getSeoFailure("network error"));
      }
    }
  } catch (err) {
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    // yield put(actions.getContentListFailure(error));
  }
}

function* getContentListSaga() {
  yield takeEvery(actionsTypes.GET_CONTENT_LIST_START, getContentList);
}

function* managePostSaga(){
  yield takeEvery(actionsTypes.MANAGE_CONTENT_START, managePost);
}

function* manageSeoSaga(){
  yield takeEvery(actionsTypes.MANAGE_SEO_START, manageSeo);
}

function* getSeoSaga(){
  yield takeEvery(actionsTypes.GET_SEO_START, getSeoData);
}

export function* contentSaga() {
  yield all([fork(getContentListSaga),fork(managePostSaga),fork(manageSeoSaga),fork(getSeoSaga),]);
}
